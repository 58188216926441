<template>
  <div>
    <headers ref="headers" />

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item  to="/forum/index">个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="menuList">
        <div
          @click="changeAct(0)"
          :style="act == 0 ? 'color:#00D8C3' : ''"
          class="menuItem"
        >
          个人资料
        </div>
        <div
          @click="changeAct(1)"
          :style="act == 1 ? 'color:#00D8C3' : ''"
          class="menuItem"
        >
          企业信息
        </div>
        <template v-if="user.type == 1">
          <div
            @click="changeAct(2)"
            :style="act == 2 ? 'color:#00D8C3' : ''"
            class="menuItem"
          >
            授权证书
          </div>
          <div
            @click="changeAct(3)"
            :style="act == 3 ? 'color:#00D8C3' : ''"
            class="menuItem"
          >
            授权软件激活码
          </div>
          <div
            @click="changeAct(4)"
            :style="act == 4 ? 'color:#00D8C3' : ''"
            class="menuItem"
          >
            员工管理
          </div>
        </template>
      </div>
      <div class="contentR">
        <div class="uploadPwd" @click="uploadPwd">修改密码</div>
        <div class="loginOut" @click="loginOut">退出登录</div>
        <div class="userInfo" id="section0">
          <div
            class="FormImgUpload"
            @mouseenter="handleMouseEnter()"
            @mouseleave="handleMouseOut()"
          >
            <FormImgUpload
              :url="headPortrait"
              @upload="upload"
              :limit="1"
              :maxSize="5"
              accept="image/jpeg,image/png,image/jpg"
              :multiple="true"
            />
          </div>
          <div class="avatar">
            <img
              :src="
                user.avatarUrl
                  ? user.avatarUrl
                  : require('@/assets/images/dfavatar.png')
              "
              alt=""
            />
            <div
              class="userMask"
              :style="{ display: textShow ? 'block' : 'none' }"
            >
              修改头像
            </div>
          </div>

          <div class="nameBox">
            <div class="name">
              {{ user.username }}
              <div>{{ user.post }}</div>
            </div>
            <div class="tel">{{ user.phone }}</div>
          </div>
        </div>

        <div class="line mt-30"></div>

        <div class="card2" v-if="user.type == 1" id="section1">
          <div class="card2Name">
            {{ company.name }}
            <div>盘厂</div>
          </div>
          <el-carousel
            class="card2Carousel"
            direction="vertical"
            :autoplay="true"
            indicator-position="none"
          >
            <el-carousel-item
              class="card2Carousel"
              v-for="(item, index) in company.equityClassifyList"
              :key="index"
            >
              <div class="dateBox" v-if="item.isExpire == 1">
                <div>{{ item.equityClassifyName }}合作伙伴</div>
                {{ timeFormat(item.endTime) }}到期
              </div>
              <div class="dateBox" style="color: #829199" v-else>
                <div
                  style="
                    background: linear-gradient(
                      180deg,
                      #fef9f3 0%,
                      #d9d9d9 100%
                    );
                    color: #829199;
                  "
                >
                  {{ item.equityClassifyName }}合作伙伴
                </div>
                已过期
              </div>
            </el-carousel-item>
          </el-carousel>

          <div class="card2Right">
            <img
              v-for="(item, index) in company.equityClassifyList"
              :key="index"
              :src="lenum[item.equityClassifyName]"
            />
          </div>
        </div>

        <div class="card2" id="section1" v-else>
          <div class="card2Name">上海良信电器股份有限公司</div>
          <div class="dateBox">
            <div>良信股份</div>
          </div>

          <div class="card2Right"></div>
        </div>

        <template v-if="user.type == 1">
          <div class="line mt-20"></div>

          <div class="card3" id="section2">
            <div class="card3Tit">授权证书</div>
            <div class="card3Box">
              <div
                class="card3Item"
                v-for="(item, index) in company.equityClassifyList"
                :key="index"
              >
                <img :src="item.certImage" />
                <div
                  class="card3ItemBtn"
                  @click="downloadImage(item.certImage)"
                >
                  下载证书
                </div>
              </div>
            </div>
          </div>

          <div class="line mt-50"></div>
          <div class="card4Tit">授权软件激活码</div>
          <div class="card4">
            <div class="card4L">
              <div class="card4LItem">
                <div class="card4LItemTop">{{ company.totalAuthNum }}</div>
                <div class="card4LItemBom">总授权数</div>
              </div>
              <div class="card4LItem">
                <div class="card4LItemTop">{{ company.usedAuthNum }}</div>
                <div class="card4LItemBom">已用授权数</div>
              </div>
              <div class="card4LItem">
                <div class="card4LItemTop">
                  {{
                    Number(company.totalAuthNum - company.usedAuthNum).toFixed(
                      0
                    )
                  }}
                </div>
                <div class="card4LItemBom">未使用授权数</div>
              </div>
            </div>
            <div class="card4R">
              <div class="card4BtnL">查看激活码</div>
              <div class="card4BtnR">申请激活码</div>
            </div>
          </div>

          <div class="line mt-30"></div>

          <div class="card5" id="section4">
            <div class="title">
              <div>员工管理</div>
              <div class="btn" @click="addUser">新增员工</div>
            </div>
            <div class="card5Card">
              <div
                class="card5CardItem"
                v-for="(item, index) in staffList"
                :key="index"
              >
                <div class="card5ItemTopBox">
                  <div class="card5ItemTopL">{{ item.username }}</div>
                  <div class="card5ItemTopR textOverflowOne">
                    {{ item.post }}
                  </div>
                </div>
                <div class="card5ItemBomBox">{{ item.phone }}</div>
                <!-- 审核中 -->
                <div v-if="item.state == 1" class="card5mask">审核中。。</div>
                <!-- 审核未通过 -->
                <div v-if="item.state == 3" class="card5maskFail">
                  <div class="card5maskFailText">
                    <div class="card5maskFailText1">审核未通过</div>
                    <div class="card5maskFailText2">{{ item.reason }}</div>
                  </div>
                  <div class="card5maskFailBtn" @click="uploadUser(item)">
                    修改
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <footers />

    <el-dialog
      title="新增员工"
      :visible.sync="dialogVisible"
      width="35%"
      :before-close="handleClose"
    >
      <el-form
        ref="userForm"
        :model="userForm"
        :rules="userFormRule"
        label-width="100px"
      >
        <el-form-item label="员工姓名" prop="username">
          <el-input
            class="input"
            placeholder="请输入员工姓名"
            maxlength="20"
            v-model="userForm.username"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工岗位" prop="post">
          <el-input
            class="input"
            maxlength="20"
            placeholder="请输入员工岗位"
            v-model="userForm.post"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工号码" prop="phone">
          <el-input
            class="input"
            placeholder="请输入员工手机号码"
            v-model="userForm.phone"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="Btn" type="primary" @click="sumbitUser"
          >提交审核</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";
import FormImgUpload from "@/components/formImgUpload";
import {
  userDetail,
  userCompany,
  userStaffList,
  userStaffAdd,
  userStaffUpdate,
  avatarUpdate,
  logOutapi,
} from "@/api/lx";
import { timeFormat } from "@/utils/index";
import L1 from "@/assets/images/L1.png";
import L2 from "@/assets/images/L2.png";
import L3 from "@/assets/images/L3.png";
import L4 from "@/assets/images/L4.png";

export default {
  name: "Procucts",
  components: {
    headers,
    footers,
    search,
    FormImgUpload,
  },
  data() {
    return {
      headPortrait: [],
      L1,
      L2,
      L3,
      L4,
      lenum: {
        L1: L1,
        L2: L2,
        L3: L3,
        L4: L4,
      },
      timeFormat,
      act: 0,
      dialogVisible: false,
      user: {
        type: 1,
      },
      company: {},
      staffList: [],
      userForm: {
        phone: "",
        post: "",
        username: "",
      },
      userFormRule: {
        phone: [
          { required: true, message: "请输入员工手机号码", trigger: "blur" },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入员工姓名", trigger: "blur" },
        ],
        post: [{ required: true, message: "请输入员工岗位", trigger: "blur" }],
      },
      // userType:1,//1盘厂2业务员
      textShow: false,
    };
  },
  created() {
    this.getUser();
  },
  mounted() {},
  methods: {
    handleMouseEnter() {
      this.textShow = true;
    },
    handleMouseOut() {
      this.textShow = false;
    },
    upload(e) {
      // https://l-xpert.obs.cn-southwest-2.myhuaweicloud.com/backstage/d1959eeb-ba8f-649f-251a-6c94e28bca3b.jpg
      console.log(e);
      avatarUpdate({
        avatarUrl: e,
      }).then((res) => {
        this.user.avatarUrl = e;
        this.$refs.headers.refresh();
        this.headPortrait = [];
      });
    },

    getUser() {
      userDetail({}).then((res) => {
        this.user = res.data;
        if (this.user.type == 1) {
          this.getUserCompany();
          this.getUserStaffList();
        }
      });
    },
    getUserCompany() {
      userCompany({}).then((res) => {
        this.company = res.data;
      });
    },
    getUserStaffList() {
      userStaffList({}).then((res) => {
        this.staffList = res.data;
      });
    },
    changeAct(val) {
      this.act = val;
      const section = document.getElementById("section" + val);
      if (section) {
        const targetTop = section.offsetTop + 75;
        console.log(section);
        window.scrollTo({
          top: targetTop,
          behavior: "smooth",
        });
      }
    },
    toSolutionDetails() {
      this.$router.push({
        path: "/forum/details",
        query: { id: 123 },
      });
    },
    addUser() {
      this.userForm.id = null;
      this.dialogVisible = true;
    },
    uploadUser(item) {
      this.userForm = JSON.parse(JSON.stringify(item));
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs["userForm"].resetFields();
      this.dialogVisible = false;
    },
    sumbitUser() {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          if (this.userForm.id) {
            userStaffUpdate(this.userForm).then(async (res) => {
              this.getUserStaffList();
              this.handleClose();
            });
          } else {
            userStaffAdd(this.userForm).then(async (res) => {
              this.getUserStaffList();
              this.handleClose();
            });
          }
        }
      });
    },
    uploadPwd() {
      this.$router.push({
        path: "/uploadPwd/index",
      });
    },
    loginOut() {
      logOutapi({}).then((res) => {
        if (res.code == 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          this.$router.push({
            path: "/",
          });
        }
      });
    },
    //下载图片
    downloadImage(val) {
      const url = val;
      const link = document.createElement("a");
      link.href = url;
      link.download = "image.jpg"; // 设置下载的文件名
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 1400px;
  margin: 0 auto;
  margin-top: 30px;
  background: #ffffff;
  padding: 40px 0;
  display: flex;
}
.menuList {
  width: 206px;
  cursor: pointer;
  padding-left: 50px;
  box-sizing: border-box;
  .menuItem {
    margin-bottom: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #525f66;
    line-height: 28px;
  }
}
.contentR {
  border-left: 1px solid #e6edf0;
  width: 1193px;
  padding: 0 50px;
  min-height: 800px;
  // height: calc(100vh - 450px);
  box-sizing: border-box;
  position: relative;
  // overflow-y: auto;
  // overflow-x: hidden;
}
.uploadPwd {
  position: absolute;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #ced9de;
  top: 7px;
  right: 50px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #525f66;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  z-index: 100;
}
.loginOut {
  position: absolute;
  cursor: pointer;
  top: 53px;
  right: 50px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #d14d21;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #d14d21;
  line-height: 20px;
  text-align: center;
  line-height: 32px;
  z-index: 100;
}
.userInfo {
  display: flex;
  position: relative;
  .FormImgUpload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1000;
    width: 78px;
    height: 78px;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    & img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .userMask {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.1);
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.7);
      text-align: center;
      line-height: 78px;
    }
  }
  .nameBox {
    display: flex;
    width: 400px;
    height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
    .name {
      display: flex;
      align-items: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 22px;
      color: #00161a;
      div {
        padding: 0 16px;
        height: 26px;
        line-height: 26px;
        background: rgba(0, 216, 195, 0.1);
        border-radius: 17px;
        font-weight: 600;
        font-size: 16px;
        color: #00d8c3;
        margin-left: 10px;
      }
    }
    .tel {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #525f66;
      margin-top: 12px;
    }
  }
}
.line {
  width: 100%;
  height: 1px;
  background: #e6edf0;
}
.card2 {
  width: 1095px;
  height: 137px;
  background-image: url("../../assets/images/myBj.png");
  background-size: 100% 100%;
  margin-top: 15px;
  padding: 46px 35px 22px 176px;
  box-sizing: border-box;
  position: relative;
  .card2Name {
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: #00161a;
    margin-bottom: 17px;
    div {
      padding: 0 14px;
      height: 26px;
      line-height: 26px;
      background: #ffffff;
      border-radius: 17px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #d4892e;
      margin-left: 10px;
    }
  }
  .card2Carousel {
    height: 26px;
  }
  .dateBox {
    width: 400px;
    height: 26px;
    display: flex;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #ff8422;
    div {
      padding: 0 14px;
      height: 26px;
      background: linear-gradient(180deg, #ffcd84 0%, #e99f48 100%);
      border-radius: 17px;
      border: 1px solid #ffffff;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 26px;
      margin-right: 10px;
    }
  }
  .card2Right {
    position: absolute;
    height: 64px;
    display: flex;
    align-items: center;
    bottom: 21px;
    right: 35px;
    img {
      width: 88px;
      height: 64px;
      margin-left: 10px;
    }
  }
}

.card3 {
  margin-top: 20px;
  .card3Tit {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: #00161a;
  }
  .card3Box {
    display: flex;
    margin-top: 15px;
    .card3Item {
      width: 206px;
      margin-right: 30px;
      img {
        width: 206px;
        height: 280px;
      }
      .card3ItemBtn {
        width: 96px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #ced9de;
        margin: 0 auto;
        margin-top: 10px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #525f66;
        cursor: pointer;
        line-height: 20px;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}
.card4Tit {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 22px;
  color: #00161a;
  line-height: 30px;
  margin-top: 30px;
}
.card4 {
  width: 100%;
  height: 98px;
  background: #ebfcfb;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card4L {
  display: flex;
  padding: 0 40px 0 45px;
  box-sizing: border-box;
}
.card4LItem {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  .card4LItemTop {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: #00161a;
  }
  .card4LItemBom {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #829199;
    margin-top: 8px;
  }
}
.card4R {
  display: flex;
  margin-right: 40px;
  cursor: pointer;
  .card4BtnL {
    width: 96px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #00d8c3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #00d8c3;
    text-align: center;
    line-height: 32px;
  }
  .card4BtnR {
    width: 96px;
    height: 32px;
    background: #00d8c3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
    margin-left: 20px;
  }
}
.card5 {
  width: 100%;
  margin-top: 32px;
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 22px;
    color: #00161a;
    .btn {
      width: 96px;
      height: 32px;
      background: #00d8c3;
      border: 1px solid #ced9de;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
    }
  }
  .card5Card {
    width: 1295px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    .card5CardItem {
      width: 346px;
      height: 97px;
      background: #ffffff;
      border: 1px solid #ced9de;
      margin-right: 24px;
      position: relative;
      padding: 20px 25px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .card5ItemTopBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card5ItemTopL {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 18px;
          color: #00161a;
        }
        .card5ItemTopR {
          width: 83px;
          height: 28px;
          background: #e5fbf9;
          border-radius: 17px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #00d8c3;
          text-align: center;
          line-height: 28px;
        }
      }
      .card5ItemBomBox {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #829199;
        margin-top: 12px;
      }
      .card5mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #00d8c3;
      }
      .card5maskFail {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        justify-content: space-between;
        padding: 20px 26px 25px 31px;
        box-sizing: border-box;
        .card5maskFailText1 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 14px;
          color: #d14d21;
        }
        .card5maskFailText2 {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #d14d21;
          margin-top: 10px;
          width: 200px;
        }
        .card5maskFailBtn {
          width: 62px;
          min-width: 62px;
          height: 32px;
          background: #00d8c3;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          cursor: pointer;
          text-align: center;
          line-height: 32px;
        }
      }
    }
  }
}
.card6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 41px;
  img {
    width: 220px;
    height: 160px;
    display: block;
  }
}
.input {
  width: 380px;
}
.Btn {
  width: 120px;
  height: 39px;
  margin-left: 200px;
  background: #00d8c3;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #00d8c3;
  &.is-active,
  &:active {
    border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
    outline: none; /* 移除默认的轮廓线 */
  }
}
</style>